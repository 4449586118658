var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.forumTopic)?_c('div',{staticClass:"ml-3 mb-2 rounded main-container"},[_c('div',{staticClass:"box-header"},[_c('router-link',{attrs:{"to":{ name: 'forums' }}},[_vm._v("Forum")]),_c('i',{staticClass:"fa fa-chevron-right mx-1"}),_c('router-link',{attrs:{"to":{ name: 'forum', params: { id: _vm.forumTopic.forum.id } }}},[_vm._v(_vm._s(_vm.forumTopic.forum.name))]),_c('i',{staticClass:"fa fa-chevron-right mx-1"}),_vm._v(" "+_vm._s(_vm.forumTopic.name)+" ")],1),_c('div',{staticClass:"box-body"},[_c('div',{staticClass:"box-content mb-2"},[_c('div',{staticClass:"mb-2 rounded"},[_c('h1',[_vm._v(" "+_vm._s(_vm.forumTopic.name)+" ")]),_c('div',[_c('span',{staticClass:"box-content-info"},[_vm._v(" "+_vm._s(_vm._f("formatTimestamp")(_vm.forumTopic.created))+" by "+_vm._s(_vm.forumTopic.comment.author.username)+" ")]),(!_vm.forumTopic.comment._isEditing)?_c('div',{staticClass:"box-content-body",domProps:{"innerHTML":_vm._s(_vm.bbCode(_vm.forumTopic.comment.content))}}):_vm._e(),(_vm.forumTopic.comment._isEditing)?_c('div',{staticClass:"box-content-body"},[_c('comment-form',{attrs:{"comment":_vm.forumTopic.comment},on:{"saved":function($event){_vm.forumTopic.comment._isEditing = false}}})],1):_vm._e(),(!_vm.forumTopic.comment._isEditing)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[(
                                    _vm.$can('edit_comment') ||
                                        (_vm.$can('edit_own_comment') &&
                                            _vm.forumTopic.comment.isCreatedBy(
                                                _vm.$user
                                            ))
                                )?_c('span',{staticClass:"float-right pointer",attrs:{"title":"Edit topic"},on:{"click":function($event){$event.preventDefault();_vm.forumTopic.comment._isEditing = true}}},[_c('i',{staticClass:"fas fa-pen"})]):_vm._e(),_c('span',{staticClass:"float-right mr-2 pointer",attrs:{"title":"Comment on topic"},on:{"click":function($event){$event.preventDefault();return _vm.scrollToCommentForm()}}},[_c('i',{staticClass:"fas fa-reply"})])])]):_vm._e()])])]),_c('comment-list',{attrs:{"type":"forum","id":_vm.forumTopic.id}})],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }